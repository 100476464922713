import api from "../axios"
import { ENDPOINT_DEV } from '../configs';



export const saveTimeZoneAPI = async (body: any) => {
  return await api
    .post(ENDPOINT_DEV + `user`, body)
    .then((res) => {
      return res;
    });
};

