import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RoutePaths } from '../modules/consts/enum';
import { getIsAuth, getResetUserCognObj } from '../modules/selectors/auth';
import _ from 'lodash';

const PublicRoute = ({ children }: { children: JSX.Element }) => {
    const isAuth = useSelector(getIsAuth);
    const resetUserCognObj = useSelector(getResetUserCognObj);
    
    return isAuth ? (
        <Redirect to={RoutePaths.Booking} />
    ) : _.isEmpty(resetUserCognObj) ? (
        <Redirect to={RoutePaths.Login} />
    ) : (
        children
    );
};

export default PublicRoute;
