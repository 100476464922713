import api from "../axios"
import { ENDPOINT_DEV } from '../configs';

export const getConnectionsByOrg = async (orgId: string) => {
    return await api
        .get(`${ENDPOINT_DEV}booking?org_id=${orgId}&futureOnly=${localStorage.getItem('futureOnly')}`)
        .then((res) => {
            return res;
        });
};
