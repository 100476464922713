import api from "./axios"
import { ENDPOINT_DEV } from './configs';

export const getOrganizationByOrgIdAPI = async (orgId: string) => {
    return await api
        .get(ENDPOINT_DEV + 'organization/' + orgId)
        .then((res) => {
            return res;
        });
};
