import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Guardtypes, RoutePaths } from '../modules/consts/enum'
import { useCheckLoggedInUser } from '../modules/hooks/useCheckLoggedInUser/useCheckLoggedInUser'
import AuthGuard from './AuthGuard'
import PrivateGuard from './PrivateGuard'
import PublicRoute from './PublicRoute';
import routes, { IRouteProps } from './routes';
import axios from 'axios';
import { ENDPOINT_DEV } from '../api/configs'
import { getDefaultAuthStorage } from '../utils/Utils'
import {AWS_EXPORTS} from "../utils/congitoUtils"
import { Auth } from 'aws-amplify'

const PrepareRoutes = () => {
  useCheckLoggedInUser();
  const [isLoading, setIsLoading] = useState(true);
  const storage = getDefaultAuthStorage();

  useEffect(() => {
    const fetchData = async () => {
        try {
            const url = new URL(window.location.href);
            const slugValue = url.pathname.split('/').pop();
            if(slugValue){
                // Fetch SAML data
                const response = await axios.get(ENDPOINT_DEV + `get-saml-data/${slugValue}/booking`);

                // Handle the response data
                const { data } = response;
                if (data.isExistOrg && data.isSSOEnabled) {
                    storage.setItem('clientId', data.client_id);
                    Auth.configure({
                        Auth: {
                            ...AWS_EXPORTS.Auth,
                            userPoolWebClientId: data.client_id,
                        },
                    });
                    await Auth.federatedSignIn({ customProvider: data.identity_provider });
                } else {
                    setIsLoading(false);
                }
            }else{
                setIsLoading(false)
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };
    fetchData();
     // eslint-disable-next-line
}, []);


    const genrateComponent = (routeProps: IRouteProps): JSX.Element => {
        switch (routeProps.guard) {
            case Guardtypes.Private:
                return (
                    <PrivateGuard>
                        <routeProps.component />
                    </PrivateGuard>
                );
            case Guardtypes.Auth:
                return (
                    <AuthGuard>
                        <routeProps.component />
                    </AuthGuard>
                );
            case Guardtypes.SetPassword:
                return (
                    <PublicRoute>
                        <routeProps.component />
                    </PublicRoute>
                );
            case Guardtypes.Public:
                return <routeProps.component />;
            default:
                if (window.location.pathname === '/') return <Redirect to={RoutePaths.Booking} />;
                return <routeProps.component />;
        }
    };
    return (
        <Switch>
            {!isLoading
             && routes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact}>
                    {genrateComponent(route)}
                </Route>
            ))}
        </Switch>
    );
};

export default PrepareRoutes;
