import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authReducer';
import organizationReducer from './organizationReducers';
import connectionReducer from './connectionsReducer';

const reducer = combineReducers({
    organization: organizationReducer,
    auth: authReducer,
    connection: connectionReducer,
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;
