import api from "../axios"
import { ENDPOINT_DEV } from '../configs';

export const updateConnectionAPI = async (sessionId: string, body: any) => {
    return await api
        .put(ENDPOINT_DEV + `booking/${sessionId}`, body)
        .then((res) => {
            return res;
        });
};
