import axios, { AxiosRequestConfig } from 'axios';
import { RoutePaths } from '../modules/consts/enum';
import { getAccessToken } from '../utils/Utils';
import { ENDPOINT_DEV } from './configs';

const axiosInstance = axios.create({
    baseURL: ENDPOINT_DEV,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    async (confA: AxiosRequestConfig) => {
        const accessToken = await getAccessToken();
        const conf = confA as Config;

        if (!conf.headers) {
            conf.headers = {};
        }
        if (!conf.custom?.excludeTokenIdFromHeader) {
            conf.headers.authorization = `Bearer ${accessToken}`;
        }
        return conf;
    },
    (error) => {
        Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const statusCode = error?.response?.status;
      if (statusCode === 401) {
          window.location.href = RoutePaths.Login;
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;

export interface TAxiosCustomConfig {
    excludeTokenIdFromHeader?: boolean;
}

export interface Config extends AxiosRequestConfig {
    custom?: TAxiosCustomConfig;
}
