import api from "./axios"
import { ENDPOINT_DEV } from './configs';

export const updateOrganizationByOrgIdAPI = async (org: any) => {
    return await api
        .put(ENDPOINT_DEV + 'organization/' + org.orgId, org.updatedOrg)
        .then((res) => {
            return res;
        });
};
