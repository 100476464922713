import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Settings } from 'luxon';
import { identify } from 'react-fullstory';
import { setResetUserCogn, setTimezone, authSuccess, setLoggedInUser, resetAuth } from '../../reducers/authReducer';
import { getDecodedToken, signOut } from '../../../utils/congitoUtils';
import { AuthKeys } from '../../consts/enum';
import { ToastProps } from '../../../components/toast/ToastModel';
import { TOAST_FADEOUT } from '../../../utils/Utils';

interface SelectedOrgIdFromEmailInterface {
    selectedOrgId: string;
}

const useKeepMeLoggedIn = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const keepMeLoggedIn = useCallback(
        // eslint-disable-next-line sonarjs/cognitive-complexity
        async (user: any, keepSignIn?: boolean, setToastVals?: any) => {
            const storage = keepSignIn ? localStorage : sessionStorage;
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                localStorage.setItem('userEmail', user.attributes.email);
                dispatch(setResetUserCogn(user));
            } else {
                const loggedInUser = getDecodedToken(user.getSignInUserSession().getIdToken().getJwtToken()) as any;
                localStorage.setItem('keepSignedIn', JSON.stringify(keepSignIn));
                if (loggedInUser) {
                    if (loggedInUser.hasOwnProperty('roles') && typeof Array.isArray(JSON.parse(loggedInUser.roles))) {
                        loggedInUser.roles = JSON.parse(loggedInUser.roles);
                    }
                    if (loggedInUser?.timeZone) {
                        Settings.defaultZone = loggedInUser?.timeZone.trim();
                        dispatch(setTimezone({ tz: loggedInUser?.timeZone.trim(), abbreviation: '' }));
                    }
                    if (
                        loggedInUser.hasOwnProperty('adminOfOrgs') &&
                        typeof Array.isArray(JSON.parse(loggedInUser.adminOfOrgs))
                    ) {
                        loggedInUser.adminOfOrgs = JSON.parse(loggedInUser.adminOfOrgs);
                    }
                    if (
                        loggedInUser.hasOwnProperty('orgsPrivileges') &&
                        typeof Array.isArray(JSON.parse(loggedInUser.orgsPrivileges))
                    ) {
                        loggedInUser.adminOfOrgs = [];
                        const orgPrivNewArr = [] as any;
                        const orgsPrivileges = JSON.parse(loggedInUser.orgsPrivileges);
                        if (Array.isArray(orgsPrivileges)) {
                            orgsPrivileges.forEach((item: any) => {
                                if (item.privileges && item.privileges.includes('booking-app')) {
                                    loggedInUser.adminOfOrgs.push(item.org_id);
                                    orgPrivNewArr.push(item);
                                }
                            });
                        }
                        if (orgPrivNewArr.length === 0) {
                            dispatch(resetAuth());
                            dispatch(setLoggedInUser({}));
                            dispatch(resetAuth());
                            showErrorAndLogout(setToastVals, true);
                            return;
                        }
                        loggedInUser.orgsPrivileges = JSON.parse(JSON.stringify(orgPrivNewArr));
                        let selectedOrgIdObj;
                        const locationState = location?.state as SelectedOrgIdFromEmailInterface;
                        if (
                            locationState &&
                            locationState.hasOwnProperty('selectedOrgId') &&
                            locationState.selectedOrgId !== null &&
                            locationState.selectedOrgId !== undefined
                        ) {
                            selectedOrgIdObj = locationState.selectedOrgId
                                ? loggedInUser.orgsPrivileges.find(
                                      (org: any) => org.org_id === locationState.selectedOrgId,
                                  )
                                : '';
                            loggedInUser.currentSelectedOrg = selectedOrgIdObj;
                        } else {
                            loggedInUser.currentSelectedOrg = loggedInUser.orgsPrivileges[0];
                        }
                    }
                    loggedInUser.currentApp = 'booking-app';
                    dispatch(authSuccess(loggedInUser));
                    dispatch(setLoggedInUser(loggedInUser));
                    const userId = loggedInUser['cognito:username'];
                    const userObject = {
                        email: loggedInUser.email,
                        userId,
                    };
                    await identify(userId, userObject);
                    // storage.setItem(AuthKeys.accessToken, user.signInUserSession.getAccessToken().getJwtToken());
                    storage.setItem(AuthKeys.idToken, user.getSignInUserSession().getIdToken().getJwtToken());
                    storage.setItem(AuthKeys.uDetails, JSON.stringify(loggedInUser));
                } else {
                    showErrorAndLogout(setToastVals, false);
                }
            }
        },
        [dispatch, location?.state],
    );

    return { keepMeLoggedIn };
};
const showErrorAndLogout = (setToastVals: any, flag: boolean) => {
    signOut();
    if (setToastVals) {
        if (flag) {
            setToastVals({
                type: 'error',
                message: 'Your account is not authorized for this application, please contact the administrator',
                msgHeader: 'Not Authorized!',
                show: true,
            });
            setTimeout(() => {
                setToastVals({
                    type: '',
                    message: '',
                    msgHeader: '',
                    show: false,
                } as ToastProps);
            }, TOAST_FADEOUT);
        } else if (!flag) {
            signOut();
            setToastVals({
                type: 'error',
                message: 'Role for the entered user not found.',
                msgHeader: 'Role Missing!',
                show: true,
            });
            setTimeout(() => {
                setToastVals({ type: '', message: '', msgHeader: '', show: false } as ToastProps);
            }, TOAST_FADEOUT);
        }
    }
};
export default useKeepMeLoggedIn;
