import React from 'react';
import { RouteProps } from 'react-router-dom';
import { Guardtypes, RoutePaths } from '../modules/consts/enum';

const Booking = React.lazy(() => import('../pages/Booking'));
const BookingForm = React.lazy(() => import('../pages/BookingForm'));
const Login = React.lazy(() => import('../pages/Login'));
const CompleteNewLogin = React.lazy(() => import('../pages/complete_new_login/CompleteNewLogin'));
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'));
const CompleteForgotPassword = React.lazy(() => import('../pages/complete-forgot-password/CompleteForgotPassword'));
const NotFound = React.lazy(() => import('../pages/Page404'));

export interface IRouteProps extends RouteProps {
    guard?: Guardtypes;
    component: React.LazyExoticComponent<() => JSX.Element>;
}

const routes: IRouteProps[] = [
    {
        path: RoutePaths.BookingForm,
        component: BookingForm,
        exact: true,
        guard: Guardtypes.Private,
    },
    {
        path: RoutePaths.Booking,
        component: Booking,
        exact: true,
        guard: Guardtypes.Private,
    },
    {
        path: RoutePaths.Login,
        component: Login,
        exact: true,
        guard: Guardtypes.Auth,
    },
    {
        path: RoutePaths.CompleteLogin,
        component: CompleteNewLogin,
        exact: true,
        guard: Guardtypes.Public,
    },
    {
        path: RoutePaths.ForgotPassword,
        component: ForgotPassword,
        exact: true,
        guard: Guardtypes.Auth,
    },
    {
        path: RoutePaths.CompleteForgotPassword,
        component: CompleteForgotPassword,
        exact: true,
        guard: Guardtypes.Auth,
    },
    {
        path: RoutePaths.NotFound,
        component: NotFound,
    },
];

export default routes;
