import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { saveTimeZoneAPI } from '../../api/bookings/saveTimeZone';
import * as Sentry from '@sentry/react';

// export interface IUser {
//   nationality: string | null,
//   location: string | null,
//   user_id: string | null,
//   last_name: string | null,
//   bio: string | null,
//   first_name: string | null,
//   profile: string | null,
//   email: string | null,
//   statement: string | null,
//   google: boolean | false,
//   role: string | null
// }

export interface AuthState {
    user: any;
    isLoading: boolean;
    isAuth: boolean;
    userCongitoObj: any;
    timezone: {
        tz: string;
        abbreviation: string;
    };
    resetUserCognObj: any;

}

const initialState: AuthState = {
    user: null,
    isLoading: false,
    isAuth: false,
    userCongitoObj: null,
    timezone: { tz: '', abbreviation: '' },
    resetUserCognObj: {}
};

export const saveTimeZone: any = createAsyncThunk('auth/saveTimeZone', async (body: any) => {
  try {
    const { data } = body;
    await saveTimeZoneAPI(data);

  } catch (error) {
    Sentry.captureException(error);
  }
});


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAuth: () => ({
            ...initialState,
        }),
        authPending: (state) => ({
            ...state,
            isLoading: true,
        }),
        authSuccess: (state, { payload }) => ({
            ...state,
            isLoading: false,
            userCongitoObj: payload,
            user: payload,
            isAuth: true,
        }),
        authFail: (state) => ({
            ...state,
            ...initialState,
        }),
        setTimezone(state, action) {
            state.timezone = action.payload;
        },
        setLoggedInUser: (state, { payload }) => ({
            ...state,
            user: payload,
        }),
        setResetUserCogn(state, action) {
            state.resetUserCognObj = action.payload;
            state.isLoading = false
        }
    },
});

export const { authPending, authSuccess, authFail, resetAuth, setTimezone, setLoggedInUser, setResetUserCogn } = authSlice.actions;
export default authSlice.reducer;
