import { Auth } from 'aws-amplify';

export const TOAST_FADEOUT = 5100;
export const GENERIC_ERROR_MESSAGE = 'Something went wrong, try after sometime!';
export const TOAST_TYPE = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning',
};
export const TOAST_MESSAGE_HEADER = {
    SUCCESS: 'Successful!',
    ERROR: 'Error!',
    INFO: 'Info!',
    WARNING: 'Warning!',
};

export const getDefaultAuthStorage = () => {
    const keepSignedIn = JSON.parse(localStorage.getItem('keepSignedIn') || '{}');
    return keepSignedIn ? localStorage : sessionStorage;
};

export const getAccessToken = async () => {
    const session = await Auth.currentSession();
    // const storage = getDefaultAuthStorage();
    // console.log(session.getIdToken().getJwtToken() === storage.getItem('idToken'), "session");
    // return storage.getItem('idToken');
    return session?.getIdToken()?.getJwtToken();
};

export const getFeatureObj = ({ featureFlags }: { featureFlags?: Array<{ feature: string; enabled: boolean }> }) => {
  const featureObject: { [key: string]: boolean } = {};

  featureFlags?.forEach((flag) => {
    featureObject[flag.feature] = flag.enabled;
  });

  return featureObject;
};

