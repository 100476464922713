import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import useKeepMeLoggedIn from '../useKeepMeLoggedIn/useKeepMeLoggedIn';
import { authPending, resetAuth } from '../../reducers/authReducer';
import { AWS_EXPORTS, signOut } from '../../../utils/congitoUtils';

export const useCheckLoggedInUser = () => {
    const { keepMeLoggedIn } = useKeepMeLoggedIn();
    const dispatch = useDispatch();

    const callSignOut = (clientId: string) => {
        Auth.signOut();
        const authConfig = Auth.configure();

        const { domain, redirectSignOut } = authConfig.oauth as { domain: string; redirectSignOut: string };
        const cognitoLogoutEndpoint = `https://${domain}/logout`;
        const logoutUrl = `${cognitoLogoutEndpoint}?client_id=${clientId}&logout_uri=${encodeURIComponent(
            redirectSignOut,
        )}`;
        signOut();
        localStorage.setItem('isRedirect', 'true');
        window.location.assign(logoutUrl);
    };

    const ionViewCanEnter = useCallback(async () => {
        try {
            const authenticatedUser = await Auth.currentAuthenticatedUser();

            if (authenticatedUser) {
                dispatch(authPending());
                keepMeLoggedIn(authenticatedUser, true);
            }
        } catch (err: any) {
            if (err?.includes('The user is not authenticated') || err?.includes('User not invited')) {
                const clientId = localStorage.getItem('clientId');
                const isRedirect = localStorage.getItem('isRedirect');
                if (clientId && !isRedirect) {
                    callSignOut(clientId);
                } else if (!isRedirect) {
                    signOut();
                    dispatch(resetAuth());
                }
            }
        }
    }, [dispatch, keepMeLoggedIn]);

    useEffect(() => {
        const clientId = localStorage.getItem('clientId');

        ionViewCanEnter();
        if (clientId) {
            Auth.configure({
                Auth: {
                    ...AWS_EXPORTS.Auth,
                    userPoolWebClientId: clientId,
                },
            });
        } else {
            Auth.configure(AWS_EXPORTS);
        }
    }, [dispatch, ionViewCanEnter]);

    return null;
};
