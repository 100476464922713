import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';

export const AWS_EXPORTS = {
    Auth: {
        region: process.env.REACT_APP_AWS_REGION || 'us-east-1',
        userPoolId: process.env.REACT_APP_USER_POOL_ID || 'us-east-1_cxdilsXQO',
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || '36aitheimoqjvckf39erm7kl6k',
        cookieStorage: {
            domain: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN,
            path: '/',
            expires: 365,
            sameSite: 'strict',
            secure: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN !== 'localhost',
        },
        oauth: {
            responseType: 'code',
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: `${process.env.REACT_APP_DOMAIN || 'http://localhost:3000'}/booking`,
            redirectSignOut: `${process.env.REACT_APP_DOMAIN || 'http://localhost:3000'}/login`,
            domain: process.env.REACT_APP_USER_POOL_DOMAIN || 'vcs-platform-dev.auth.us-east-1.amazoncognito.com',
        },
    },
};

/**
 * Get formated names for the orgs
 * @param orgs
 * @returns
 */
export const getFormatedOrgNames = (orgs: any) => {
    const newOrgs = [] as any;
    orgs.forEach((item: any) => {
        let strOrgId = '';
        if (item.org_id.includes('-')) {
            strOrgId = item.org_id.replace('-', ' ');
        } else if (item.org_id.includes('_')) {
            strOrgId = item.org_id.replace('_', ' ');
        } else {
            strOrgId = item.org_id;
        }
        item.orgName = strOrgId;
        newOrgs.push(item);
    });
    return newOrgs;
};

export const getDecodedToken = (encodedToken: string) => {
    return jwt_decode(encodedToken);
};

export const signOut = () => {
    Auth.signOut();
    clearAuthStorage(sessionStorage);
    clearAuthStorage(localStorage);
};

export const clearAuthStorage = (storage: Storage) => {
    storage.removeItem('accessToken');
    storage.removeItem('idToken');
    storage.removeItem('uDetails');
    storage.removeItem('clientId')
};

export const getAuthFromStorage = (storage: Storage) => {
    const idToken = storage.getItem('idToken');
    const accessToken = storage.getItem('accessToken');
    const uDetails = storage.getItem('uDetails');

    let valid = false;

    if (
        uDetails !== null &&
        uDetails !== '' &&
        uDetails !== undefined &&
        accessToken !== null &&
        accessToken !== '' &&
        accessToken !== undefined &&
        idToken !== null &&
        idToken !== '' &&
        idToken !== undefined
    ) {
        valid = true;
    } else {
        clearAuthStorage(storage);
    }

    return {
        idToken: idToken ?? '',
        accessToken: accessToken ?? '',
        uDetails: uDetails ?? '',
        valid,
    };
    // return false
};
export const getDefaultAuthStorage = () => {
    const keepSignedIn = JSON.parse(localStorage.getItem('keepSignedIn') || '{}');
    return keepSignedIn ? localStorage : sessionStorage;
};
