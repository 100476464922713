// import axios from 'axios';
import api from "../axios"
import { ENDPOINT_DEV } from '../configs';

export const createNewConnectionAPI = async (body: any) => {
    return await api
        .post(ENDPOINT_DEV + `booking`, body)
        .then((res) => {
            return res;
        });
};
