import React from 'react';
import ReactDOM from 'react-dom/client';

import './scss/style.scss';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import { onServiceWorkerUpdate } from './utils/ServiceWorkerUpdater';
import App from './App';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: 'portals-booking-app@' + process.env.REACT_APP_VERSION,
    integrations: [
        new Integrations.BrowserTracing(),
        // This will give ability to see correct path in the dev tools
        new Sentry.Integrations.Breadcrumbs({
            console: false,
        }),
    ],
    tracesSampleRate: 1.0,
});

export const Loading = () => (
    <div className="pt-3 text-center">
        <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>
);
const root = ReactDOM.createRoot(document.getElementById('root') as Element);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register({
//     onUpdate: onServiceWorkerUpdate,
// });
