import React, { Suspense } from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './modules/store';
import './scss/style.scss';
import PrepareRoutes from './routes/PrepareRoutes';
import { ErrorBoundary } from './components/ErrorBoundary';
import FullStory from "react-fullstory";

export const Loading = () => (
    <div className="pt-3 text-center">
        <div className="spinner-grow" role="status">
      <span className="sr-only">Loading....</span>
        </div>
    </div>
);
// eslint-disable-next-line no-console
console.log(process.env.REACT_APP_FULLSTORY_ORG_ID as string, "org id fullstory")
const App = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <FullStory org={process.env.REACT_APP_FULLSTORY_ORG_ID as string}/>
                <Suspense fallback={<Loading />}>
                    <ErrorBoundary>
                        <PrepareRoutes />
                    </ErrorBoundary>
                </Suspense>
            </BrowserRouter>
        </Provider>
    );
};

export default App;
